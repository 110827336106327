import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer_component";
import { Dropdown, Space } from "antd";

// 菜单项
const items = [
  {
    key: "1",
    label: (
      <a
        style={{ color: "#2757c3" }}
        className="atagnav"
        target="_blank"
        rel="noopener noreferrer"
        href="/searchnav"
      >
        专利检索
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        style={{ color: "#2757c3" }}
        target="_blank"
        className="atagnav"
        rel="noopener noreferrer"
        href="/ainav"
      >
        人工智能
      </a>
    ),
  },
];

// Layout 组件
export const Layout_component = () => {
  return (
    <>
      <div className="container">
        <header className="d-flex flex-wrap justify-content-center py-3 border-bottom">
          <a
            href="/"
            className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
          >
            <img
              src="/images/icon.svg"
              width="30"
              height="30"
              alt="Product Logo"
            />
          </a>

          <ul className="nav nav-pills">
            <li className="nav-item">
              <a href="/" className="nav-link" aria-current="page">
                首页
              </a>
            </li>
            <li className="nav-item">
              <a href="/todolist" className="nav-link">
                提醒事项
              </a>
            </li>
            <li className="nav-item">
              <a href="/write" className="nav-link">
                撰写
              </a>
            </li>
            <li className="nav-item">
              <a href="/aiwrite" className="nav-link">
                AI辅助撰写
              </a>
            </li>
            <li className="nav-item">
              <a href="/figures" className="nav-link">
                附图
              </a>
            </li>
            <li className="nav-item">
              <a href="/check" className="nav-link">
                自检
              </a>
            </li>
             <li class="nav-item">
              <a href="/book" class="nav-link">
                审查指南
              </a>
            </li>
            <li class="nav-item">
              <a href="/check" class="nav-link">
                <Dropdown
                  placement="bottom"
                  overlayStyle={{
                    color: "purple",
                  }}
                  menu={{
                    items,
                  }}
                >
                  <a
                    style={{ textDecoration: "none" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    网址导航
                  </a>
                </Dropdown>
              </a>
            </li>
          </ul>
        </header>
      </div>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout_component;
