import React from "react";
const Home_component = () => {
  return (
    <>
      <main>
        {/* 第一行 */}
        <div className="px-4 py-5 my-5 text-center" style={{ minHeight: "50vh" }}>
          <h1 className="display-5 fw-bold">必备专利工具</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              通过领先的算法，助力专利从业人员提升质量和生产力。
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button type="button" className="btn btn-primary btn-lg px-4 gap-3">
                <a
                  href="/write"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  现在开始
                </a>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4"
              >
                <a href="/write" style={{ textDecoration: "none" }}>
                  了解更多
                </a>
              </button>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/file.svg"
                    style={{ width: "40vw", Color: "red" }}
                    alt="专利文件图标"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 第二行 */}
        <div className="container px-4 py-5" id="featured-3">
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">简单重复工作</h3>
              <p>
                几乎每个专利申请文件都需要重复做很多工作，例如添加附图标号、删除附图标号及复制权利要求书到发明内容等。
              </p>
              <a
                href="/write"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">错误的风险</h3>
              <p>
                专利申请文件的形式问题有一定的要求，若写了两个逗号；又或者在说明书中出现了“其特征在于”等词语则可能会收到补正通知书。
              </p>
              <a
                href="/check"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">技术理解慢</h3>
              <p>
                由于技术交底书质量差，又或者技术领域比较偏等原因，导致技术方案理解慢，从而影响撰写效率。
              </p>
              <a
                href="/aiwrite"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
          </div>
        </div>
        {/* 第三行 */}
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="/images/addmark.svg"
                className="d-block mx-lg-auto img-fluid"
                alt="添加附图标号"
                width="700"
                height="500"
                loading="lazy"
              ></img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">添加、删除附图标号</h1>
              <p className="lead">
                在撰写界面中，输入附图标记，并输入权利要求书或说明书，点击相应的按钮，即可添加附图标号；并且，在撰写界面中，输入待删除标号的权利要求书或说明书，，点击相应的按钮，即可删除附图标号。
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  <a
                    href="/write#firstcontainer"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    添加附图标号
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  <a
                    href="/write#secondcontainer"
                    style={{ textDecoration: "none" }}
                  >
                    删除附图标号
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* 第四行 */}
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">一键生成发明内容</h1>
              <p className="lead">
                最大化提高生产力。通过识别权利要求书中的关键词，自动化去除改关键词（例如其特征在于），用户只需将权利要求书复制到输入框中，点击按钮，即可一键生成发明内容。
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  <a
                    href="/write#thirdcontainer"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    生成发明内容
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  <a
                    href="/write#thirdcontainer"
                    style={{ textDecoration: "none" }}
                  >
                    生成实用新型内容
                  </a>
                </button>
              </div>
            </div>
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="/images/inventor_content.svg"
                className="d-block mx-lg-auto img-fluid"
                alt="生成发明内容，生成实用新型内容"
                width="700"
                height="500"
                loading="lazy"
              ></img>
            </div>
          </div>
        </div>
        {/* 第五行 */}
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="/images/svg_generate/convert.png"
                className="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              ></img>
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">模型图转黑白线稿图</h1>
              <p className="lead">
                在附图界面中，上传SolidWorks等三维建模软件制作的模型图，自动生成白底黑线的黑白线稿图，方便专利代理师在windows画图软件中添加附图标号。
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  <a
                    href="/figures"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    现在开始
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  了解更多
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* 第五行 */}
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">AI辅助撰写</h1>
              <p className="lead">
                借助人工智能，可以向AI提问，帮你撰写背景技术，技术效果，整理技术交底书，理解技术方案，通俗的解释技术方案，理解对比文件，翻译专利文件等，提高撰写和答复OA的效率。
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  <a
                    href="/aiwrite"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    AI撰写技术效果
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  <a href="/aiwrite" style={{ textDecoration: "none" }}>
                    AI撰写背景技术
                  </a>
                </button>
              </div>
            </div>
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="/images/aichat.svg"
                className="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              ></img>
            </div>
          </div>
        </div>
        {/* 第六行 */}
        <div className="container px-4 py-5" id="featured-3">
          <h2 className="pb-2 border-bottom"> 安全且用户友好</h2>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">天生安全</h3>
              <p>
                本网页应用在您的本地计算机/网络（本地部署）上运行，不会暴露您的数据。
              </p>
              <a
                href="/write"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">易于使用</h3>
              <p>
                您可以直接在浏览器中轻松使用，无需下载，也无需考虑运行环境，随时随地使用。
              </p>
              <a
                href="/write"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
            <div className="feature col">
              <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                <svg className="bi" width="1em" height="1em"></svg>
              </div>
              <h3 className="fs-2">量身定制</h3>
              <p>
                基于专利代理师的实际需求，专为专利代理师量身定制，致力于减轻专利代理师的负担。
              </p>
              <a
                href="/write"
                className="icon-link d-inline-flex align-items-center"
              >
                现在开始
                <svg className="bi" width="1em" height="1em"></svg>
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Home_component;
