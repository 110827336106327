import React from "react";

const Book_component = () => {
  return (
    <iframe
      src="/book/patent_examination_guidelines_compressed.pdf"
      width="100%"
      height="675px"
    >
      审查指南
    </iframe>
  );
};
export default Book_component;
