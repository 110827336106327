import React from "react";
import { ReactTyped } from "react-typed";

const FiguresTypeWriter = () => (
  <ReactTyped
    strings={["将照片转换成CAD线稿"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default FiguresTypeWriter;
