import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout_component";
import Home from "./components/Home_component";
import Write from "./components/Write_component";
import Check from "./components/Check_component";
import Aiwrite from "./components/Aiwrite_component";
import Ainav from "./components/Ainav_component";
import Searchnav from "./components/Searchnav_component";
import Todoist from "./components/Todoist_component";
import Figures from "./components/Figures_component";
import Book from "./components/Book_component";


function App() {
  return (
    <BrowserRouter
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
    >
      <Routes>
        {/* 根路由，渲染 Layout */}
        <Route path="/" element={<Layout />}>
          {/* 默认首页 */}
          <Route index element={<Home />} />
          {/* 子路由：/todolist */}
          <Route path="todolist" element={<Todoist />} />
          {/* 子路由：/write */}
          <Route path="write" element={<Write />} />
          {/* 子路由：/aiwrite */}
          <Route path="aiwrite" element={<Aiwrite />} />
          {/* 子路由：/figures */}
          <Route path="figures" element={<Figures />} />
          {/* 子路由：/check */}
          <Route path="check" element={<Check />} />
           {/* 子路由：/审查指南 */}
          <Route path="book" element={<Book />} />
          {/* 子路由：/searchnav */}
          <Route path="searchnav" element={<Searchnav />} />
          {/* 子路由：/ainav */}
          <Route path="ainav" element={<Ainav />} />
          {/* 404 =>首页页面 */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
