import React, { useState } from "react";
import WriteTypeWriterTianjiaFutuBiaoJi from "../../typewriter/write/WriteTypeWriterTianjiaFutuBiaoJi";
import WriteService from "../../../../services/write_service";

// AddMarkSection 组件用于处理附图标记的添加功能
export const AddMarkSection = () => {
  // 定义附图标记说明的状态变量和更新函数
  const [futuBiaojiShuoming, setfutuBiaojiShuoming] = useState("");
  const handleFutuMarkShuoming = (e) => {
    setfutuBiaojiShuoming(e.target.value.replace(/\s+/g, "")); // 去除空格
  };

  // 定义待添加标号的权利要求书或说明书的状态变量和更新函数
  const [needAddMarkData, setneedAddMarkData] = useState("");
  const handleneedAddMarkData = (e) => {
    setneedAddMarkData(e.target.value);
  };

  // 定义输出框数据的状态变量
  const [outAddMarkData, setOutAddMarkData] = useState("");

  // 执行添加附图标记操作：处理权利要求书的附图标记
  const handleAddClaimsAddMark = async () => {
    // 调用服务获取附图标记的结果
    let response = await WriteService.addClaimMarkService(
      futuBiaojiShuoming,
      needAddMarkData
    );

    // 设置输出框的数据
    if (response) {
      setOutAddMarkData(response);
    } else {
      console.error("添加附图标记失败");
    }
  };

  // 执行添加附图标记操作：处理说明书的附图标记
  const handleInstrucMark = async () => {
    // 调用服务获取附图标记的结果
    let response = await WriteService.addInstrucMarkService(
      futuBiaojiShuoming,
      needAddMarkData
    );

    // 设置输出框的数据
    if (response) {
      setOutAddMarkData(response);
    } else {
      console.error("添加附图标记失败");
    }
  };

  return (
    <>
      {/* 添加附图标记功能模块 */}
      <section id="firstcontainer" className="fourthcontainer">
        <h2 className="addMarkHeader">
          <WriteTypeWriterTianjiaFutuBiaoJi />
        </h2>
        <div className="container text-center bodyContainer">
          <div className="row">
            <div className="col-md-1" />
            {/* 左侧输入框，供用户输入附图标记说明和待添加标号的权利要求书或说明书 */}
            <div className="col-md-4 leftInput">
              <h4>附图标记说明</h4>
              <textarea
                className="futubiaojishuoming"
                placeholder="请按照如下格式在此处输入附图标记:
1、手机外壳；11、第一壳体；12、第二壳体；2、屏幕。"
                defaultValue={""}
                onChange={handleFutuMarkShuoming}
              />
              <h4>权利要求书或说明书</h4>
              <textarea
                className="parts-before addMarkBefore"
                placeholder="请在此处输入待添加标号的权利要求书或说明书"
                defaultValue={""}
                onChange={handleneedAddMarkData}
              />
            </div>
            {/* 中间按钮，用户点击按钮触发附图标记添加操作 */}
            <div className="col-md-2 centerBtnContainer">
              <button
                onClick={handleAddClaimsAddMark}
                type="button"
                className="btn btn-primary btnPosition"
              >
                权利要求书
              </button>
              <button
                onClick={handleInstrucMark}
                type="button"
                className="btn btn-primary btnPosition"
              >
                说明书
              </button>
            </div>
            {/* 右侧输出框，用于展示附图标记的结果 */}
            <div className="col-md-4 rigthOutput">
              <h4>输出框</h4>
              <textarea
                className="firstShuchukuang"
                placeholder="点击按钮，即可为权利要求书或说明书添加附图标记"
                value={outAddMarkData}
                onChange={() => {}} // 输出框不需要更新值
              />
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </section>
    </>
  );
};

export default AddMarkSection;
