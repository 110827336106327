import React from "react";
import AddMarkSection from "../components/units/section/write/AddMarkSection";
import DeleteMarkSection from "../components/units/section/write/DeleteMarkSection";
import GeneratedContent from "../components/units/section/write/GeneratedContent";
const Write_component = () => {
  return (
    <>
      {" "}
      <main className="write">
        {/* 添加附图标记功能块 */}
        <AddMarkSection />
        {/* 删除附图标记功能块 */}
        <DeleteMarkSection />
        {/* 生成发明内容功能块 */}
        <GeneratedContent />
      </main>
    </>
  );
};
export default Write_component;
