import React, { useState, useEffect } from "react";
import TodolistTypewriter from "./units/typewriter/todolist/TodolistTypewriter";

const Todoist_component = () => {
  const [toDoListData, setToDoListData] = useState([]);
  const [inputData, setInputData] = useState("");

  // 提取日期格式化函数
  const getFormattedDate = (date) => {
    return new Date(date).toLocaleString("zh-CN", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  // 从 localStorage 加载数据
  useEffect(() => {
    const savedToDoList = localStorage.getItem("todoList");
    if (savedToDoList) {
      setToDoListData(JSON.parse(savedToDoList)); // 解析并设置状态
    }
  }, []);

  // 保存到 localStorage
  const saveToLocalStorage = (newList) => {
    localStorage.setItem("todoList", JSON.stringify(newList)); // 将新的待办事项列表存储到 localStorage
  };

  // 删除待办事项
  const handleDeleteList = (index) => {
    const updatedList = toDoListData.filter((_, i) => i !== index);
    setToDoListData(updatedList);
    saveToLocalStorage(updatedList); // 删除后更新 localStorage
  };

  // 输入框内容更新
  const handleInputData = (e) => {
    setInputData(e.target.value);
  };

  // 添加新的待办事项
  const handleAddList = () => {
    if (toDoListData.length > 8) {
      alert("数据过多，影响浏览器性能，请删除一些事项");
      return;
    }
    const newTodo = {
      text: inputData,
      date: new Date(),
    };
    const updatedList = [...toDoListData, newTodo];
    setToDoListData(updatedList);
    saveToLocalStorage(updatedList); // 添加后更新 localStorage
    setInputData(""); // 清空输入框
  };

  return (
    <div style={{ height: "76vh" }}>
      <div className="bg-light-gray">
        <h2 className="text-center m-4">
          <TodolistTypewriter />
        </h2>
        <div className="container text-left">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <ul
                className="list-group list-group-flush"
                style={{
                  maxHeight: "50vh", // 限制列表的最大高度
                  overflowY: "auto", // 超出部分显示滚动条
                }}
              >
                {toDoListData.map((item, index) => (
                  <li key={index} className="list-group-item">
                    <svg
                      onClick={() => handleDeleteList(index)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      className="bi bi-trash3-fill m-2 mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg>
                    {item.text}
                    <div style={{ fontSize: "0.8rem" }}>
                      {getFormattedDate(item.date)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
        <div className="container text-left">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="input-group mb-3">
                <input
                  value={inputData}
                  onChange={handleInputData}
                  type="text"
                  className="form-control"
                  placeholder="请输入待添加的提醒事项..."
                />
                <button
                  onClick={handleAddList}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  新增
                </button>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Todoist_component;
