import React, { useState } from "react"; // 导入React和useState
import {
  InboxOutlined,
  DownloadOutlined,
  RightCircleTwoTone,
} from "@ant-design/icons"; // 导入图标组件
import { message, Upload } from "antd"; // 导入Ant Design的message、Upload和Rate组件
import { Button } from "antd"; // 导入Ant Design的Button组件
import FiguresTypeWriter from "./units/typewriter/figures/FiguresTypeWriter";
const { Dragger } = Upload; // 从Upload组件中解构出Dragger组件

const Figures_component = () => {
  const [uploadedImage, setUploadedImage] = useState(null); // 用于存储上传图片的预览路径
  const [generatedFile, setGeneratedFile] = useState(null); // 用于存储生成的文件下载路径

  const props = {
    name: "file", // 上传文件时使用的字段名称
    showUploadList: false, // 隐藏上传列表
    multiple: false, // 禁止同时上传多个文件
    action: "https://www.techexplore.cn/figures", // 文件上传的后端接口地址
    onChange(info) {
      const { status } = info.file; // 获取文件状态
      if (status !== "uploading") {
        // 如果文件没有在上传
        console.log(info.file, info.fileList); // 控制台打印上传文件信息
      }
      if (status === "done") {
        // 文件上传成功
        message.success(`${info.file.name} 文件上传成功！`); // 提示文件上传成功
        console.log("info.file.response", info.file.response); // 控制台打印服务器返回的响应
        const { originalFileUrl, processedFileUrl } = info.file.response; // 获取服务器返回的文件地址
        console.log(originalFileUrl, processedFileUrl); // 控制台打印文件地址
        setUploadedImage(processedFileUrl); // 设置上传图片的预览路径
        setGeneratedFile(processedFileUrl); // 设置生成文件的下载地址
      } else if (status === "error") {
        // 文件上传失败
        message.error(`${info.file.name} 文件上传失败！`); // 提示文件上传失败
      }
    },
    onDrop(e) {
      // 文件拖放时的回调函数
      console.log("拖放的文件", e.dataTransfer.files); // 控制台打印拖拽上传的文件信息
    },
  };

  return (
    <>
      <div className="container text-center">
        {/* 上部分区域 */}
        <div className="px-4 py-5 my-5 text-center mt-1">
          {/* 页面标题区 */}
          <h2 className="fw-bold">
            <FiguresTypeWriter />
          </h2>
          {/* 页面标题 */}
          <div className="col-lg-6 mx-auto">
            {/* 内容区域 */}
            <p className="lead mb-4">
              {/* 描述文本 */}
              我们的网站提供快速且精准的照片转CAD线稿功能，支持多种图片格式上传，
              自动将照片转化为类似CAD线条图，极大提升工作效率，方便专利代理师使用windows自带的画图应用添加附图标号。
            </p>
            <img
              className="introduce_img"
              src="/images/svg_generate/convert.png"
              alt=""
            />
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              {/* 排版布局 */}
            </div>
          </div>
        </div>
        {/* 下部分区域 */}
        <div className="row photoTranDiv">
          {/* 左边区域 */}
          <div className="col-lg-5 col-md-12">
            <Dragger {...props}>
              {/* 上传组件 */}
              <p className="ant-upload-drag-icon">
                {/* 上传图标 */}
                <InboxOutlined /> {/* 上传图标 */}
              </p>
              <p className="ant-upload-text">点击或拖动文件到此区域上传</p>
              {/* 上传提示文本 */}
              <p className="ant-upload-hint">
                {/* 上传规则提示 */}
                支持单次上传png/jpg格式图片。禁止上传敏感文件。
              </p>
            </Dragger>
          </div>
          {/* 中间区域 */}
          <div className="col-lg-2 col-md-12 centerdiv">
            <RightCircleTwoTone style={{ fontSize: "50px" }} />
          </div>
          {/* 右边区域 */}
          <div className="col-lg-5 col-md-12 rightOutputDiv container text-center">
            <div className="row">
              <div className="col">
                <img
                  src={uploadedImage} //上传成功后显示的图片
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  alt="CAD线稿显示区域"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button
                  block
                  className="rightBtn" // 按钮样式
                  type="primary" // 按钮类型
                  shape="round" // 按钮形状
                  size="large" // 按钮大小
                  icon={<DownloadOutlined />} // 按钮图标
                  disabled={!generatedFile} // 如果没有生成文件，禁用按钮
                  href={generatedFile} // 下载文件的链接
                  download // 设置为下载按钮
                >
                  下载
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Figures_component; // 导出组件
