import React from "react";
import AiNavNavTypeWriterGuonei from "./units/typewriter/ainav/AiNavNavTypeWriterGuonei";
import AiNavTypeWriterGuoJi from "./units/typewriter/ainav/AiNavTypeWriterGuoJi";
import AiNavTypeWriterGuoneiJingXiang from "./units/typewriter/ainav/AiNavTypeWriterGuoneiJingXiang";
import AiNavTypeWriterAiPainter from "./units/typewriter/ainav/AiNavTypeWriterAiPainter";
export const Ainav_component = () => {
  return (
    <main className="chinese">
      <section id="chinese_ai">
        <h2 className="class_h2 guoneiAiNavHeader">
          <AiNavNavTypeWriterGuonei />
        </h2>
        <div className="container text-center">
          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://kimi.moonshot.cn/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  Kimi 智能助手
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://www.deepseek.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  DeepSeek
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://tongyi.aliyun.com/qianwen/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  通义千问
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://xinghuo.xfyun.cn/desk"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  讯飞星火
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://ai.360.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  智脑
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://www.baichuan-ai.com/home"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  百川大模型
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://www.doubao.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  豆包
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://search.tiangong.cn/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  天工
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://chatglm.cn/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  智谱清言
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://www.yucongming.com"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  鱼聪明AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://hunyuan.tencent.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  腾讯混元
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://yiyan.baidu.com/welcome"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  文心一言
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="foreign_ai">
        <h2 className="class_h2 guowaiAiNavHeader">
          <AiNavTypeWriterGuoJi />
        </h2>
        <div className="container text-center">
          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://chatgpt.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  ChatGPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://llama.meta.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  Llama
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://gemini.google.com/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  Gemini
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://www.anthropic.com/index/claude-now-in-slack"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  Claude AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn"></div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn"></div>
            </div>
          </div>
        </div>
      </section>

      <section id="chinaChatgpt">
        <h2 className="class_h2 guoneiJingXiangHeader">
          <AiNavTypeWriterGuoneiJingXiang />
        </h2>
        <div className="container text-center">
          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="http://chat.bltcy.top/"
                  rel="nofollow"
                >
                  柏拉图GPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="http://gpt33.cn/"
                  rel="nofollow"
                >
                  盖酷AI实验室
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="http://new.cutim.top/"
                  rel="nofollow"
                >
                  ChatGPTWeb
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="http://www.ealxc.com/"
                  rel="nofollow"
                >
                  AI Tools
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://1.1ai.fun"
                  rel="nofollow"
                >
                  ai.fun
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://1.bixin123.com/"
                  rel="nofollow"
                >
                  比心123问答绘画
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://3am.com.hk"
                  rel="nofollow"
                >
                  AM
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://aappt.opao.xyz"
                  rel="nofollow"
                >
                  opao.xyz
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.berlin4h.top/"
                  rel="nofollow"
                >
                  ChatWeb
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.blueelephantai.cn"
                  rel="nofollow"
                >
                  蓝象AI对话
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.ci"
                  rel="nofollow"
                >
                  ai.ci
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.dfcsf.asia"
                  rel="nofollow"
                >
                  Openai Chat Web
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.hxkj.vip"
                  rel="nofollow"
                >
                  hxkj.vip
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.ls/"
                  rel="nofollow"
                >
                  ai.ls
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ai.minihuo.com/"
                  rel="nofollow"
                >
                  核桃
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://aibusx.com/"
                  rel="nofollow"
                >
                  aibusx.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://airoom.chat"
                  rel="nofollow"
                >
                  airoom.chat
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://aitopk.com/list"
                  rel="nofollow"
                >
                  GPT-Plus共享
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://aivesa.cn/"
                  rel="nofollow"
                >
                  AIVESA 智能
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://aixforce.app/"
                  rel="nofollow"
                >
                  aixforce.app
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://b9.Betai55.uk/"
                  rel="nofollow"
                >
                  ChatGPT Web
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://beta.character.ai/"
                  rel="nofollow"
                >
                  Character.AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://cgs.skybyte.me/"
                  rel="nofollow"
                >
                  ChatBot
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.51buygpt.com/"
                  rel="nofollow"
                >
                  ChatGPT社区免费版
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.ai365vip.com/"
                  rel="nofollow"
                >
                  Ai365
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.anchongai.com"
                  rel="nofollow"
                >
                  暗虫AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.forefront.ai/"
                  rel="nofollow"
                >
                  Forefront.ai
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.kaopuai.com"
                  rel="nofollow"
                >
                  靠谱AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.nuu.su"
                  rel="nofollow"
                >
                  云衍AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.tig.im/"
                  rel="nofollow"
                >
                  TIG.AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.uniation.net/"
                  rel="nofollow"
                >
                  GPT中文站
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.weijiajin.com/"
                  rel="nofollow"
                >
                  完全免费无广告GPT3.5
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat.zonas.wang"
                  rel="nofollow"
                >
                  PhantoNav AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat10.wuguokai.xyz/"
                  rel="nofollow"
                >
                  ChatGPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat2doc.cn/"
                  rel="nofollow"
                >
                  chat2doc.cn
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chat35.com/"
                  rel="nofollow"
                >
                  chat35.com
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatai.lra.cn/"
                  rel="nofollow"
                >
                  lra.cn
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatbot.js.cn"
                  rel="nofollow"
                >
                  我的AI小助理
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatbot.theb.ai/"
                  rel="nofollow"
                >
                  TheB.AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatcat.zhaoyoung.me/"
                  rel="nofollow"
                >
                  ChatCat AI Chatgirl
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatgpt.kungfu.wang/"
                  rel="nofollow"
                >
                  AIGC小站
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatgpt35.theinspiredgroup.com/"
                  rel="nofollow"
                >
                  ChatGPT - AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://chatz.free2gpt.xyz"
                  rel="nofollow"
                >
                  Free2GPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://cnsgpt.fun/"
                  rel="nofollow"
                >
                  CnsGPT.fun
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://codenews.cc/chatgpt"
                  rel="nofollow"
                >
                  ChatGPT
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://desk.im/"
                  rel="nofollow"
                >
                  desk.im
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://fby.xiamis.xyz/"
                  rel="nofollow"
                >
                  智慧虾米AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://free.netfly.top"
                  rel="nofollow"
                >
                  netfly.top
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://free-accounts.vercel.app/"
                  rel="nofollow"
                >
                  free-accounts.vercel.app
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gonnai.com/"
                  rel="nofollow"
                >
                  closeai.red
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gpt.freet.top"
                  rel="nofollow"
                >
                  奇途AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gpt4.intercat.cn/"
                  rel="nofollow"
                >
                  intercat.cn
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gpt-all.site/"
                  rel="nofollow"
                >
                  gpt-all.site
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gptbot.me/"
                  rel="nofollow"
                >
                  gptbot.me
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://gptplus.io/"
                  rel="nofollow"
                >
                  gptplus.io
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://hai.dongstop.link/"
                  rel="nofollow"
                >
                  dongstop.link
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://home.topai.vip/"
                  rel="nofollow"
                >
                  GPT4.0国内免魔法直用
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://huggingface.co/spaces/AUST001/ChatGPT"
                  rel="nofollow"
                >
                  huggingface.co
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ic.free-chat.asia/"
                  rel="nofollow"
                >
                  ic.free-chat.asia
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://idea.chat.cloud.copilot.xr21.me/"
                  rel="nofollow"
                >
                  X-Copilot
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ideaiai.com/"
                  rel="nofollow"
                >
                  创想AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://link3.cc/aiearthdev"
                  rel="nofollow"
                >
                  AIO通用智能服务平台
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://nbchat.github.io/ai/"
                  rel="nofollow"
                >
                  nbchat.github.io
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://newchat.hklan.top/"
                  rel="nofollow"
                >
                  HKlan-AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://openmao.panchuang.net/"
                  rel="nofollow"
                >
                  开放猫
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://openprompt.co/ChatGPT"
                  rel="nofollow"
                >
                  openprompt.co
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ora.sh/openai/gpt4"
                  rel="nofollow"
                >
                  ora.sh
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://poe.com/"
                  rel="nofollow"
                >
                  Poe
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://powerchat.top/"
                  rel="nofollow"
                >
                  powerchat.top
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://share.wendabao.net/"
                  rel="nofollow"
                >
                  AI问答宝
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://site.easygpt.work/"
                  rel="nofollow"
                >
                  EasyGPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://tchat.icu"
                  rel="nofollow"
                >
                  tchat.icu
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://toyaml.com/chat.html"
                  rel="nofollow"
                >
                  toyaml.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://wielded.com/"
                  rel="nofollow"
                >
                  Wielded
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://wow.xjai.pro"
                  rel="nofollow"
                >
                  动点助手
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://wrtn.ai/"
                  rel="nofollow"
                >
                  wrtn.ai
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.ai2dog.com/bavarder"
                  rel="nofollow"
                >
                  AI柯基
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.aishort.top/"
                  rel="nofollow"
                >
                  AI Short
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.atalk-ai.com/talk"
                  rel="nofollow"
                >
                  海鲸AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.chat2chatgpt.com/?from=ai555"
                  rel="nofollow"
                >
                  ChatGPT原生态
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.chatyuhua.cn/"
                  rel="nofollow"
                >
                  语画AI
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.gnomic.cn/"
                  rel="nofollow"
                >
                  Gnomic智能体平台
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.kejilequ.com"
                  rel="nofollow"
                >
                  柯基助手
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.ohmygpt.com/"
                  rel="nofollow"
                >
                  OhMyGPT
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.promptboom.com/"
                  rel="nofollow"
                >
                  PowerChat
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.teach-anything.com/"
                  rel="nofollow"
                >
                  Teach Anything
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.tomchat.work"
                  rel="nofollow"
                >
                  TomGPT
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.wu-ji.com/"
                  rel="nofollow"
                >
                  无际AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.xiamoai.top/"
                  rel="nofollow"
                >
                  xiamoai.top
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.zaiwen.top/"
                  rel="nofollow"
                >
                  在问
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://www.zhidouai.com/chat/"
                  rel="nofollow"
                >
                  知豆Ai - AIGC聚合写作绘画
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://xhlgpt.com/"
                  rel="nofollow"
                >
                  小狐狸GPT
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://xmfgpt.com/"
                  rel="nofollow"
                >
                  xmfgpt.com
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://yesiamai.com/"
                  rel="nofollow"
                >
                  YesIamAI-是的我是AI
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://ymi.ai/?channel=J03"
                  rel="nofollow"
                >
                  ymi.ai
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  className="list-group-item list-group-item-action list-group-item-light"
                  href="https://zyq-chatgpt.github.io"
                  rel="nofollow"
                >
                  zyq-chatgpt.github.io
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ai_painting">
        <h2 className="class_h2 aiPainterHeader">
          <AiNavTypeWriterAiPainter />
        </h2>
        <div className="container text-center">
          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://miaohua.sensetime.com/inspiration?invite_code=wWxy4kmmP"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  商汤秒画
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://tu.360.cn/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  鸿图
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://mj1.freemj.xyz/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  免费 Midjourney 绘画
                </a>
              </div>
            </div>
          </div>

          {/* 行 */}
          <div className="row ">
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="https://hh.gpihh.cc/"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  瓜皮AI绘画 (MJ)
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn">
                <a
                  href="http://ww12.chilloutai.xyz/?usid=15&utid=29007880078"
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  chilloutai.xyz
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 p-2">
              <div className="aiNavdivBtn"></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Ainav_component;
